import { Link } from "react-router-dom";
import "./pages.css";
const Sistemi88mm = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  return (
    <div className="container-fluid blueev-main">
      <div className="row bluerowspace">
        <div className="col-lg-7 col-md-12 col-sm-12 infotextt">
          <h2>
            88мм Системи
            <br />
            <span className="colored-text">Kömmerling</span>
          </h2>
          <p>
          Системите со централно затворање Kömmerling 88 се развиени специфично за нови објекти. Овој комплетен систем за прозорци и врати за живеалишта е идеално решение за сите потреби за топлинската изолација, вклучувајќи го и стандардот за пасивни куќи.
          </p>
        </div>
      </div>
      <div className="row bluerowspace mm88Padding">
        <div className="col-lg-4 col-md-12 col-sm-12 align-self-start pageImagesText">
          <div>
            <img
              className="kommerlingTypes"
              src="./images/Sistem88Standard1.jpg"
              alt=""
            />
          </div>
          <div>
            <h5 className="whiteText">
              <span className="colored-text">Kömmerling 88</span>
              <br />
              Централно Затворање
            </h5>
          </div>
          <p className="infoPadTop">
            Тоа е премиум прозорец за нова ера: Kömmerling 88. Предводлив во
            топлосна изолација. Напреден во дизајн. Пионер во квалитет и
            безбедност. И со револуционерна индивидуалност благодарение на
            својата модуларност и иновативни технологии за надградба.
          </p>
          <div className="row textStartRow">
            <div className="col-12 textStartCol"><Link to="/PremiumProzorskiSistem" className="mmSubLinks" onClick={handleClick}><i class="fa-solid fa-chevron-right"></i> Стандарден</Link></div>
            <div className="col-12 textStartCol" ><Link to="/AluKlip88mm" className="mmSubLinks" onClick={handleClick}><i class="fa-solid fa-chevron-right"></i> АлуКлип</Link></div>
          </div> 
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 align-self-start pageImagesText">
          <div>
            <img
              className="kommerlingTypes"
              src="./images/88ADstandard in white.jpg"
              alt=""
            />
          </div>
          <div>
            <h5 className="whiteText">
              <span className="colored-text">Kömmerling 88</span>
              <br />
              Двојно Затворање
            </h5>
          </div>
          <p className="infoPadTop">
            Тоа е премиум прозорец за нова ера: Kömmerling 88. Предводлив во
            топлосна изолација. Напреден во дизајн. Пионер во квалитет и
            безбедност. И со револуционерна индивидуалност благодарение на
            својата модуларност и иновативни технологии за надградба.
          </p>
          <div className="row textStartRow">
            <div className="col-12 textStartCol"><Link to="/DvojnoZatvoranje88mm" className="mmSubLinks" onClick={handleClick}><i class="fa-solid fa-chevron-right"></i> Стандарден</Link></div>
          </div> 
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 align-self-start pageImagesText">
          <div>
            <img
              className="kommerlingTypes"
              src="./images/Kommerling3.jpg"
              alt=""
            />
          </div>
          <div>
            <h5 className="whiteText">
              <span className="colored-text">Kömmerling 88</span>
              <br />
              Влезна Врата за живеалишта
            </h5>
          </div>
          <p className="infoPadTop">
            Тоа е премиум прозорец за нова ера: Kömmerling 88. Предводлив во
            топлосна изолација. Напреден во дизајн. Пионер во квалитет и
            безбедност. И со револуционерна индивидуалност благодарение на
            својата модуларност и иновативни технологии за надградба.
          </p>
          <div className="row textStartRow">
            <div className="col-12 textStartCol"><Link to="/AluKlip88mmVnatresno" className="mmSubLinks" onClick={handleClick}><i class="fa-solid fa-chevron-right"></i> АлуКлип внатрешно отворање</Link></div>
            <div className="col-12 textStartCol"><Link to="/AluKlip88mmNadvoresno" className="mmSubLinks" onClick={handleClick}><i class="fa-solid fa-chevron-right"></i> АлуКлип надворешно отворање</Link></div>
          </div> 
        </div>
      </div>
    </div>
  );
};

export default Sistemi88mm;
