import './pages.css';
const Dvojno76mmStandard = () => {
    const handleDownloadClick = () => {
    // Replace 'path/to/your/pdf/document.pdf' with the actual path or URL of your PDF document
    const pdfSource = './images/Koemmerling-76-double-seal-standard-brochure.pdf';

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and href
    link.download = 'Koemmerling-76-double-seal-standard-brochure.pdf';
    link.href = pdfSource;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document body after the download starts
    document.body.removeChild(link);
  };
  return (
    <div className="container-fluid komBottomMargin">
        <div className='row kommerlingRowMarginLess'>
          <div className='col-12'>
          <h4><span className='colored-text'>Kömmerling 76 Стандарден</span> со двојно затворање</h4><br/>
          <div id="carouselExampleControls" className="carousel slide imageFloat" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img className="d-block w-100" src="./images/76double seal standard white.jpg" alt="First slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/76double seal standard proCoverTec Titanium single-sided.jpg" alt="Second slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/76DoubleSeal standard walnut.jpg" alt="Third slide"/>
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
      <p>Системот со двојно затворање Kömmerling 76 ги нуди сите предности што може да ви ги обезбедат модерните прозорци. Овој систем со централно затворање, со пет камери и конструкциска длабочина од 76 мм, нуди фасцинантна комбинација на модерен дизајн и совршени пропорции на профилот.
Без разлика дали станува збор за функционалност, структурни својства, изолациски вредности, заштита на животната средина или зачувување на вредноста, тој ги исполнува барањата и на денешницата, и претежно на иднината. Kömmerling 76 системот со двојно затворање е толку иновативен дизајн што може да прифати модерни тројни стакла или посебни функционални стакла со ширина до 50 мм.
Благодарение на интелигентната технологија за прицврстување, професионалната инсталација е брза, чиста и безнапорна. Замената на старите прозорци со нови модерни системи со двојно затворање Kömmerling 76, не може да биде поедноставно. Дури и стандардната верзија на системот ги исполнува барањата за нискоенергетски куќи - Uf вредност = 1.0 W/(m²K). Интелигентниот целосен дизајн ја подобрува топлинската и звучната изолација, а вие имате корист од поголем квалитет на живеење, економичност и безбедност. Спојте го обликот и бојата онака како што посакувате. Обширниот асортиман на профили - со офсет и полу-офсетна рамка - и различни завршни обработки на површината предлага многу можности. Системот е достапен во бела боја, декоративни ламинати со изглед на дрво, или рамната или метална боја - ламинирани од надворешна страна и бели од внатрешната, или ламинирани и од двете страни. Покрај тоа, Kömmerling 76 е достапен со иновативната технологија на површината proCoverTec во уште 26 бои.
Овој систем исто така поставува пример од еколошка гледна точка - Комерлинг брендот е првиот клучен производител во светот кој произведува профили не само со рециклирани материјали во чист PVC-U, туку и исклучиво со PVC без олово со "зелени" стабилизатори базирани на калциум и цинк. Ова гарантира дека оние кои ги користат Комерлинг продуктите исто така работат на заштита на животната средина.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
          <div className='buttonBottom'>
<button className='mmInfoVazno'>Најважно</button>
<button className='mmInfoBrosuri' onClick={handleDownloadClick}>Брошури</button>
</div>
<ul className='mmDetails'>
  <li>Лесна грижа благодарение на робусните и времеско отпорните површини.</li>
  <li>Опционална интегрирана заштита од кражба за поголема безбедност.</li>
  <li>Совршена заштита од бучава за подобрар квалитет на живеење.</li>
  <li>Tрајна вредност благодарение на издржливиот квалитет на највисоко ниво.</li>
  <li>Сите PVC-U профили се произведени со стабилизатори без олово, базирани на калциум и цинк.</li>
  <li>Нов високоизолациски 76 мм систем за значителна штедња на трошоците и енергијата – Uf вредност 
од 1.1 W/(m²K) само за стандардната верзија.</li>
</ul>
          </div>
        </div>
    </div>
  );
};

export default Dvojno76mmStandard;