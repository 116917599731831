import './pages.css';
const Komarnici = () => {
    return ( 
        <div className="container-fluid blueev-main">
        <div className="row bluerowspace">
            <div className="col-lg-6 col-md-12 col-sm-12">
            <img className="salamanderphoto pagesImages" src="./images/komarnik123.jpeg" alt="" />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 infotext">
            <h2><span className='colored-text'>Пилсе комарници</span></h2>
            <p>Плисе комарници се специјален вид комарници кои се користат за заштита од инсекти и комарци, додека истовремено овозможуваат циркулација на воздухот и светлина внатре во просторијата. Овие комарници се флексибилни и може да се користат од двете страни. Ја имаат истата можност за отварање и затварање на комарникот и однадвор и одвнатре. Овој комарник е изработен од високо квалитетни алуминиумски делови и плисирана мрежа од фиберглас – превиткана како армоника. Плисе комарникот има модерен дизајн и се одликува со висока отпорност на механички удари на мрежата, а неговата компактност – собирањето на мрежата во страна – латерално, претставува и заштеда на простор. Изведба како еднокрилен или двокрилен плисе комарник.</p>
            </div>
        </div>
    </div>
     );
}
 
export default Komarnici;