import { Link } from "react-router-dom";
import "./pages.css";
const Kommerling = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  return (
    <div className="container-fluid">
      <div className="row roletni">
        <div className="col-lg-4 col-md-12 col-sm-12 roletnileft">
          <div className="containerroletna">
            <img src="./images/slika3.jpg" alt="" />
            <Link to="/SistemZaProzoriVrati" onClick={handleClick}>
              <div class="overlay"></div>
            </Link>
            <Link to="/SistemZaProzoriVrati" onClick={handleClick}><div class="centered-text">Системи за прозорци и станбени врати</div></Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 roletniright">
          <div className="containerroletna">
            <img src="./images/slika2.jpg" alt="" />
            <Link to="/HibridniSistemi" onClick={handleClick}>
              <div class="overlay"></div>
            </Link>
            <Link to="/HibridniSistemi" onClick={handleClick}><div class="centered-text">Хибридни системи</div></Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 roletniright">
          <div className="containerroletna">
            <img src="./images/prag2.jpg" alt="" />
            <Link to="/SistemiNaPragovi" onClick={handleClick}>
              <div class="overlay"></div>
            </Link>
            <Link to="/SistemiNaPragovi" onClick={handleClick}><div class="centered-text">Системи на прагови</div></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kommerling;
