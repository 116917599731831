import './pages.css';
const Sistemi70mm = () => {
  const handleDownloadClick = () => {
    // Replace 'path/to/your/pdf/document.pdf' with the actual path or URL of your PDF document
    const pdfSource = './images/Koemmerling-70-double-seal-main-brochure.pdf';

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and href
    link.download = 'Koemmerling-70-double-seal-main-brochure.pdf';
    link.href = pdfSource;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document body after the download starts
    document.body.removeChild(link);
  };
  return (
    <div className="container-fluid komBottomMargin">
      <h3>70мм Системи <br/><span className='colored-text'>Kömmerling</span></h3>
<p className='textMarginTop70mm'>За апликации каде што се бараат мали длабочини на изградбата, достапни се системи со двојно затворање и централно затворање. Системите со двојно затворање се опремени со два нивоа на затворање. Надворешниот временски затвор е во ногата на рамката, додека внатрешниот е внатре во ногата на крилото.</p>
        <div className='row kommerlingRowMarginLess'>
          <div className='col-12'>
          <h4><span className='colored-text'>Kömmerling 70 стандард -</span> Широк опсег на стакло за мала длабочина на поставувањe</h4><br/>
          <div id="carouselExampleControls" className="carousel slide imageFloat" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img className="d-block w-100" src="./images/70mm1.jpg" alt="First slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/70double seal standard anthracite grey.jpg" alt="Second slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/70double seal standard Golden Oak.jpg" alt="Third slide"/>
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
      <p>Kömmerling 70 PVC-U системот за прозорци со длабочина на изградба од 70 мм може да прифати стакла со дебелина од 10–44 мм. Дизајнот со пет камери прикажува топлосна изолација со вредност Uf до 1.2 W/(m²K). Офсетниот дизајн на прави линии и тенките профилни видни линии овозможуваат оптимална количина светлина и обезбедуваат поголем добив на соларна енергија. Интелигентниот целосен дизајн подобрува топлосна и звучна изолација, а вие имате корист од поголем квалитет на живот, ефикасност и безбедност.
Различните завршни обработки обезбедуваат многу можности. Системот е достапен во бела, дрвена, или равна или метална боја – ламиниран на надворешната страна и бел на внатрешната, или ламиниран на двете страни.
Овој систем исто така поставува пример од еколошка гледна точка – Комерлинг брендот е првиот главен производител во светот кој произведува профили не само со рециклирани материјали во чист PVC-U, туку и исклучиво со PVC без олово со "зелени" стабилизатори базирани на калциум и цинк. Ова гарантира дека оние кои ги користат Комерлинг производите исто така работат на заштита на животната средина.
</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
          <div className='buttonBottom'>
<button className='mmInfoVazno'>Најважно</button>
<button className='mmInfoBrosuri' onClick={handleDownloadClick}>Брошури</button>
</div>
<ul className='mmDetails'>
  <li>Класичен бел, ламиниран во дрвена, равна, или метална боја.</li>
  <li>Совршена заштита од бучава за подобрен квалитет на живот.</li>
  <li>Лесно одржување благодарение на здравите и временски отпорни површини. </li>
  <li>Дизајн на прозорци со прави линии и офсет, со длабочина на изградба од 70 мм. </li>
  <li>Систем со двојно затворање што може да прифати стакла со дебелина од 10–44 мм</li>
  <li>Дизајн со пет камери за добри топлосни изолациони својства - Uf вредност до 1.2 W/(m2K).</li>
  <li>Сите PVC-U профили се произведени со стабилизатори без олово, базирани на калциум и цинк.</li>
  <li>Стабилен профилски пресек благодарение на интелигентниот дизајн за долг животен век на прозорецот.</li>
</ul>
          </div>
        </div>
    </div>
  );
};

export default Sistemi70mm;