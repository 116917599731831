import { Link } from "react-router-dom";
import './navbar.css';

const Navbar = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };
  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="far-leftside">
        <Link className="navbar-brand" to="/" onClick={handleClick}>
          <img className="logoimage" src="./images/OKNALOGO12.svg" alt="" />
        </Link>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon">
          <i className="fa-solid fa-bars"></i>
        </span>
      </button>
      <div
        className="collapse navbar-collapse center-list-items"
        id="navbarNavDropdown"
      >
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/About" onClick={handleClick}>
              За нас
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link"
              to="Kommerling"
              id="navbarDropdown1"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={handleClick}
            >
              ПВЦ Прозорци и Врати
            </Link>           
             <div className="dropdown-menu" aria-labelledby="navbarDropdown2">
             <Link className="dropdown-item subItemHide" to="/SistemZaProzoriVrati" onClick={handleClick}>
                    Системи за прозорци и станбени врати
                  </Link>
                  <Link className="dropdown-item subItemHide" to="/HibridniSistemi" onClick={handleClick}>
                    Хибридни системи
                  </Link>
                  <Link className="dropdown-item subItemHide" to="/SistemiNaPragovi" onClick={handleClick}>
                    Системи на прагови
                  </Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link"
              to="/Roletni"
              id="navbarDropdown2"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={handleClick}
            >
              Ролетни
            </Link>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown2">
              <Link className="dropdown-item" to="AluminskiRoletni" onClick={handleClick}>
                Алумински
              </Link>
              <Link className="dropdown-item" to="PvcRoletni" onClick={handleClick}>
                ПВЦ
              </Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link"
              to="Komarnici"
              id="navbarDropdown3"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={handleClick}
            >
              Комарници
            </Link>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown3">
              <Link className="dropdown-item" to="Komarnici" onClick={handleClick}>
                Плисе
              </Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link"
              to="PvcDodatoci"
              id="navbarDropdown2"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={handleClick}
            >
              ПВЦ Додатоци
            </Link>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown2">
              <Link className="dropdown-item" to="PodprozorskaDaska" onClick={handleClick}>
                Подпрозорска даска
              </Link>
              <Link className="dropdown-item" to="AluminskiOkapnik" onClick={handleClick}>
                Алумински окапник
              </Link>
              <Link className="dropdown-item" to="ZebraVenecianeri" onClick={handleClick}>
                Зебра завеси и венецијанери
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="Contact" onClick={handleClick}>
              Контакт
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Blog" onClick={handleClick}>
              Blog
            </Link>
          </li>
        </ul>
        <div className=" far-rightside">
         {/*<Link className="nav-link search-container" to="/" id="search-icon">
            <i className="fas fa-search"></i>
  </Link> */}
          <div className="nav-item dropdown langselect">
            <img className="language-flag" src="./images/mk.svg" alt="" />
            <div className="dropdown-menu wassap" aria-labelledby="languageDropdown">
              <Link className="dropdown-item" to="/" onClick={handleClick}>
                MK
              </Link>
            {/*}  <Link className="dropdown-item" to="/" onClick={handleClick}>
                EN
</Link>*/}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
