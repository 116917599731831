import { Link } from "react-router-dom";
import "./pages.css";
const SistemZaProzoriVrati = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  return (
    <div className="container-fluid blueev-main">
      <div className="row bluerowspace">
        <div className="col-lg-6 col-md-12 col-sm-12 pagesImages">
          <img className="salamanderphoto" src="./images/winwin-1.png" alt="" />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 infotext">
          <h2>
            Системи за прозорци и станбени врати<br/><span className="colored-text">Kömmerling</span>
          </h2>
          <p>
          Kömmerling претставува широк спектар на системи за прозорци и ролетни. Нашиот широко разновиден опсег на системи, исто така, обезбедува основа за креативни решенија приспособени конкретно на сосема индивидуални потреби.
          </p>
        </div>
      </div>
      <div className="row bluerowspace">
        <div className="col-lg-4 col-md-4 col-sm-12 pagesImages">
        <Link to="/Sistemi88mm" onClick={handleClick}><img className="kommerlingTypes" src="./images/Kommerling3.jpg" alt="" /></Link>
        <Link to="/Sistemi88mm" onClick={handleClick} className="center88Text"><p className="whiteText"><span className='colored-text'>Kömmerling</span><br/>88 мм системи</p></Link>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 pagesImages">
        <Link to="/Sistemi76mm" onClick={handleClick}><img className="kommerlingTypes" src="./images/Kommerling2.jpg" alt="" /></Link>
        <Link to="/Sistemi76mm" onClick={handleClick} className="center88Text"><p className="whiteText"><span className='colored-text'>Kömmerling</span><br/>76 мм системи</p></Link>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 pagesImages">
        <Link to="/Sistemi70mm" onClick={handleClick}><img className="kommerlingTypes" src="./images/Kommerling1.jpg" alt="" /></Link>
        <Link to="/Sistemi70mm" onClick={handleClick} className="center88Text"><p className="whiteText"><span className='colored-text'>Kömmerling</span><br/>70 мм системи</p></Link>
        </div>
      </div>
    </div>
  );
};

export default SistemZaProzoriVrati;