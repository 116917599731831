import './pages.css';
const AluminskiRoletni = () => {
    return ( 
        <div className="container-fluid blueev-main">
        <div className="row bluerowspace">
            <div className="col-lg-6 col-md-12 col-sm-12 imageside">
            <img className="salamanderphoto pagesImages" src="./images/aluminskaroletna123.webp" alt="" />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 infotext">
            <h2><span className='colored-text'>Алуминиумски надворешни ролетни</span></h2>
            <p>
* Овие ролетни се поставени на надворешната страна на прозорецот<br/>
* Целосната изработка е од алуминиум.<br/>
* Алуминиумската кутија не е исполнета со полиуретан, додека алуминиумските ламели се. Ширината на ламелите е 39 мм.<br/>
* Слепата кутија може да биде пентагонална или со малку заоблени рабови<br/>
* Погонот може да биде ремен, јаже или електричен мотор<br/>
* Бои: бела или во RAL бои по барање на купувачот</p>
            </div>
        </div>
        <div className="row bluerowspace">
            <div className="col-lg-6 col-md-12 col-sm-12 infotext">
                <h2><span className='colored-text'>Алуминиумските надградни ролетни</span></h2>
                <p>*Поставени се над прозорецот<br/>
*Целосното производство е изработено од алуминиум<br/>
*Алуминиумската кутија и алуминиумските летви се исполнети со полиуретан<br/>
*Кутијата на ролетната има едноставен дизајн со малку заоблени рабови<br/>
*Конструкцијата на кутијата спречува навлегување на вода, ветер, високи и ниски температури, а погодна е за прва инсталација (надградба) или реновирање (надворешна), со или без мрежи против комарци.
</p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 order-first order-md-1 pagesImages">
                <img className="salamanderphoto pagesImages aluRoletna" src="./images/aluminskaroletna111.jpeg" alt="" />
            </div>
        </div>
    </div>
     );
}
 
export default AluminskiRoletni;