import { Link } from "react-router-dom";
import "./pages.css";
const PvcDodatoci = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  return (
    <div className="container-fluid">
      <div className="row roletni">
        <div className="col-lg-4 col-md-12 col-sm-12 roletnileft">
          <div className="containerroletna">
            <img src="./images/home4.11.jpg" alt="" />
            <Link to="/PodprozorskaDaska" onClick={handleClick}>
              <div class="overlay"></div>
            </Link>
            <Link to="/PodprozorskaDaska" onClick={handleClick}><div class="centered-text">Подпрозорска даска</div></Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 roletniright">
          <div className="containerroletna">
            <img src="./images/home5.11.jpg" alt="" />
            <Link to="/AluminskiOkapnik" onClick={handleClick}>
              <div class="overlay"></div>
            </Link>
            <Link to="/AluminskiOkapnik" onClick={handleClick}><div class="centered-text">Алумински окапник</div></Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 roletniright">
          <div className="containerroletna">
            <img src="./images/roletni1.jpg" alt="" />
            <Link to="/ZebraVenecianeri" onClick={handleClick}>
              <div class="overlay"></div>
            </Link>
            <Link to="/ZebraVenecianeri" onClick={handleClick}><div class="centered-text">Зебра завеси и венецијанери</div></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PvcDodatoci;
