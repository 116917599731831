import './pages.css';
const Centralno76mmAluKlip = () => {
  const handleDownloadClick = () => {
    // Replace 'path/to/your/pdf/document.pdf' with the actual path or URL of your PDF document
    const pdfSource = './images/Koemmerling-76-centre-seal-AluClip-brochure';

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and href
    link.download = 'Koemmerling-76-centre-seal-AluClip-brochure';
    link.href = pdfSource;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document body after the download starts
    document.body.removeChild(link);
  };
  return (
    <div className="container-fluid komBottomMargin">
        <div className='row kommerlingRowMarginLess'>
          <div className='col-12'>
          <h4><span className='colored-text'>Kömmerling 76 АлуКлип</span> со централно затворање</h4><br/>
          <div id="carouselExampleControls" className="carousel slide imageFloat" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img className="d-block w-100" src="./images/76centre seal AluClip a.jpg" alt="First slide"/>
    </div>
  </div>
</div>
      <p>Системот на PVC-U прозор со алуминиумско обложување што се поставува со клип-он метод обединува предности од двата материјали во еден структурен елемент. Надворешно, алуминиумското обложување нуди практично неограничени можности за дизајн со бои, додека на внатрешноста PVC-U профилите обезбедуваат ниско одржување, висока димензионална стабилност и пулсирачка функционалност.
Овој систем ги нуди сите предности што модерните прозори можат да ви ги обезбедат. Овој централен систем со шест камери и изградбена длабочина од 76 мм нуди фасцинантна комбинација на модерен дизајн и совршени пропорции на профилот. Без разлика дали станува збор за функционалност, структурни својства, изолациски вредности, заштита на животната средина или зачувување на вредноста, тој ги исполнува барањата и на денешницата, и претежно на иднината. Комерлинг 76 централен систем е толку иновативен дизајн што може да го прифати модерното тројно стакло или специјално функционално стакло со стакла до 50 мм дебелина. Системот ги исполнува барањата за куќите со ниска потрошувачка на енергија - Uf вредност = 1.1 W/(m²K). Интелигентниот целосен дизајн ja подобрува топлинската и звучната изолација, што значи дека би имале подобар квалитет на живеење, економичност и безбедност. 
Овој систем исто така поставува пример од еколошка гледна точка - Комерлинг брендот е првиот клучен производител во светот кој произведува профили не само со рециклирани материјали во чист PVC-U, туку и исклучиво со PVC без олово со "зелени" стабилизатори базирани на калциум и цинк. Ова гарантира дека оние кои ги користат Комерлинг продуктите исто така работат на заштита на животната средина.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
          <div className='buttonBottom'>
<button className='mmInfoVazno'>Најважно</button>
<button className='mmInfoBrosuri' onClick={handleDownloadClick}>Брошури</button>
</div>
<ul className='mmDetails'>
  <li>Лесно за чистење, трајно и отпорно.</li>
  <li>Надворешен изглед на алуминиум, внатрешно неутрално бело.</li>
  <li>Изнесен дизајн на прозорци со рамни линии со споени алуминиумски профили.</li>
  <li>Зачувување на ресурси со користење само на минимална количина алуминиум.</li>
  <li>Сите PVC-U профили се произведени со стабилизатори без олово базирани на калциум и цинк.</li>
  <li>Опционална интегрирана заштита од пробивање за поголема безбедност.</li>
  <li>Екстраординарна термална изолација во споредба со алуминиумски прозорци - Uf вредност = 1,1 W/(m²K).</li>
  <li>Скоро неограничен избор на бои на алуминиумските облози поради техники на завршување како анодизација и порцеланско покривање.</li>
</ul>
          </div>
        </div>
    </div>
  );
};

export default Centralno76mmAluKlip;