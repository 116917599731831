import "./pages.css";
const AluminskiOkapnik = () => {
  return (
    <div className="container-fluid blueev-main">
      <div className="row bluerowspace">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <img
            className="salamanderphoto pagesImages"
            src="./images/komarnici1.jpg"
            alt=""
          />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 infotext">
          <h2>
            <span className="colored-text">Алуминиумски окапник</span>
          </h2>
          <p>
            Алуминиумскиот окапник во контекст на ПВЦ прозори обично се користи
            за да го заштити и подобри визуелниот и функционалниот аспект на
            прозорскиот рам. Еве неколку улоги и предности на алуминиумскиот
            окапник кога се користи со ПВЦ прозори:
          </p>
        </div>
      </div>
      <div className="row">
        <div className="offset-lg-1 col-lg-2 col-md-12 col-sm-12">
          <h4>
            <span className="colored-text">Заштита од Временски Услови:</span>
          </h4>
          <p>
            Алуминиумскиот окапник служи како заштитен слој за ПВЦ прозорите,
            помагајќи им да издржат влијанието на различни временски услови како
            дожд, снег, сонце и ветер. Ова може да им помогне на прозорите да
            останат издржливи и функционални подолго.
          </p>
        </div>
        <div className="col-lg-2 col-md-12 col-sm-12">
          <h4>
            <span className="colored-text">Визуелен Аспект:</span>
          </h4>
          <p>
            Алуминиумскиот окапник може да допринесе кон визуелниот изглед на
            прозорците. Тој може да биде дизајниран со различни стилски елементи
            и бои, што може да придонесе кон општата естетика на зградата.
          </p>
        </div>
        <div className="col-lg-2 col-md-12 col-sm-12">
          <h4>
            <span className="colored-text">Одржување и Трајност:</span>
          </h4>
          <p>
          Алуминиумот е материјал кој е отпорен на корозија и рѓа, што го прави идеален за околини со влага. Користењето на алуминиумски окапник со ПВЦ прозори може да ги заштити прозорците од оштетувања поради влага и други елементи.
          </p>
        </div>
        <div className="col-lg-2 col-md-12 col-sm-12">
          <h4>
            <span className="colored-text">Лесно Чистење и Одржување:</span>
          </h4>
          <p>
          Алуминиумскиот окапник може да биде лесен за чистење и одржување, што го прави подобар избор за места каде што се изложени на замрсување или замазнување.
          </p>
        </div>
        <div className="col-lg-2 col-md-12 col-sm-12">
          <h4>
            <span className="colored-text">Површинска Заштита:</span>
          </h4>
          <p>
          Алуминиумскиот окапник може да служи и како дополнителна површинска заштита за ПВЦ прозорците, чувајќи ги од исцртување или оштетување на површината.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AluminskiOkapnik;
