import './pages.css';
const About = () => {
    return ( 
        <div className="aboutInfo">
            <div className='backgroundKomer'>
<h3>Нашиот тим</h3><br/><br/>
<p>ОКНА е основана од тим искусни професионалци со долгогодишно искуство во градежната  индустрија. Нашиот тим е посветен на обезбедување исклучителни услуги за клиентите со тоа што им помагаме да го најдат совршениот избор на ПВЦ решенија за нивните домови.</p><br/><br></br>
        <h3>Нашите вредности</h3><br/>
        <p>Се гордееме што сме во тек со најновите трендови и нудиме широк асортиман на висококвалитетни производи кои одговараат на секој стил и буџет. Нашите вредности на интегритет, квалитет, и одржливост ги усмеруваат нашите одлуки и го дефинираат нашиот идентитет во индустријата.</p><br/><br/>
        <h3>Мисија</h3><br/>
        <p>Нашата мисија е да го трансформираме вашиот простор и да ви обезбедиме доверба и задоволство во секој аспект на нашата работа.</p><br/><br/>
        <p>Откријте ја разликата со ОКНА - онаму каде што стручноста се сретнува со извонредноста на  ПВЦ занаетот.</p>
        </div>
        </div>
     );
}
 
export default About;