import './pages.css';
const PodprozorskaDaska = () => {
    return ( 
        <div className="container-fluid blueev-main">
        <div className="row bluerowspace">
            <div className="col-lg-6 col-md-12 col-sm-12">
            <img className="salamanderphoto pagesImages daskaSize" src="./images/podprozorskadaska123.jpeg" alt="" />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 infotext">
            <h2><span className='colored-text'>Подпрозорска даска</span></h2>
            <p>Идеално дополнување на сите прозорски системи се нашите подпрозорски даски кои се лесни за одржување.<br/><br/>

Карактеристики:<br/><br/>

Расположиви димензии: 00 mm - 00 mm<br/>
Ширина: 00 mm<br/>
Дебелична на површината: по мерка,<br/>
Макс.должини на монтажа до 6 m<br/>
Постојаност на температура: -30 °C до +60 °C<br/>
Одлична изолација<br/>
Тешко запаливо<br/>
Површински слој: постојан на огреботини,влијанија од светлина</p>
            </div>
        </div>
    </div>
     );
}
 
export default PodprozorskaDaska;