// Popup.js
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import "./popup.css";

Modal.setAppElement("#root");

const Popup = ({ isOpen, onClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (isOpen) {
        console.log("Popup opened after a delay");
      }
    }, 5000);

    return () => clearTimeout(timerId);
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Log the form data
    console.log("Form submitted:", { name, email, message });

    // Perform any additional validation or processing here

    // Send the form data to the server or perform any other actions

    // Clear the form after submission if needed
    setName("");
    setEmail("");
    setMessage("");

    // Close the modal
    onClose();
  };
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className="wrapper">
        <button className="close-button" onClick={onClose}>
          <i className="fa-regular fa-circle-xmark"></i>
        </button>
        <h2 className="colored-text smallScreenPopUp">Контактирајте нè</h2>
        <Link
          className="textwhite"
          to="https://maps.app.goo.gl/5xmCza6Svk833GVHA"
          target="_blank"
          onClick={handleClick}
        >
          <div className="popUpItems">
            <i className="footerIcon fa-solid fa-location-dot"></i>Ул.1520 бр.2 Скопје
          </div>
        </Link>
        <Link
          className="textwhite"
          to="https:/www.whatsapp.com/"
          target="_blank"
          onClick={handleClick}
        >
        <div className="popUpItems">
          <i className="footerIcon fa-brands fa-whatsapp"></i>+389 71 700 760
        </div>
        </Link>
       {/* <Link
          className="textwhite"
          to="https://www.instagram.com/salamander_deutschland/"
          target="_blank"
          onClick={handleClick}
        >
          <div className="popUpItems">
            <i className="footerIcon fa-brands fa-instagram"></i>ОКНА_Пвц
          </div>
  </Link>*/}
        <Link
          className="textwhite"
          to="https://www.facebook.com/profile.php?id=61553231966045"
          target="_blank"
          onClick={handleClick}
        >
          <div className="popUpItems">
            <i className="footerIcon fa-brands fa-facebook"></i>ОКНА_Пвц
          </div>
        </Link>
        <Link
          className="textwhite"
          to="Contact"
          target="_blank"
          onClick={handleClick}
        >
          <div className="popUpItems">
            <i className="footerIcon fa-regular fa-envelope"></i>admin@okna.com
          </div>
        </Link>
      </div>
      <h2 className="colored-text smallScreenPopUp">Пишете ни порака</h2>
      <form name="contact-form" method="POST" data-netlify="true" onSubmit={handleSubmit}>
      <input type="hidden" name="form-name" value="contact-form" />
        <label className="popupwidth">
          <input
            required
            className="popupwidth zaz"
            placeholder="Име"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <br />
        <label className="popupwidth">
          <input
            required
            className="popupwidth zaz"
            placeholder="Меил адреса"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <br />
        <label className="popupwidth">
          <textarea
            className="popupwidth zaz"
            placeholder="Порака"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </label>
        <br />
        <p className="termsConditionsFont">
          I have read and agree to the{" "}
          <Link to="TermsPrivacy">terms and conditions.</Link>
        </p>
        <button type="submit" className="submitpopup">
          Испрати
        </button>
      </form>
    </Modal>
  );
};

export default Popup;
