import './pages.css';
const AluKlip88mm = () => {
  const handleDownloadClick = () => {
    // Replace 'path/to/your/pdf/document.pdf' with the actual path or URL of your PDF document
    const pdfSource = './images/Koemmerling-88-centre-seal-AluClip-brochure.pdf';

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and href
    link.download = 'Koemmerling-88-centre-seal-AluClip-brochure.pdf';
    link.href = pdfSource;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document body after the download starts
    document.body.removeChild(link);
  };
  return (
    <div className="container-fluid komBottomMargin">
        <div className='row kommerlingRowMarginLess'>
          <div className='col-12'>
          <h4>Премиум прозорски систем - <span className='colored-text'>Kömmerling 88 АлуКлип</span></h4><br/>
          <div id="carouselExampleControls" className="carousel slide imageFloat" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img className="d-block w-100" src="./images/88CentralAluKlip1.jpg" alt="First slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/88CentralAluKlip2.jpg" alt="Second slide"/>
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
      <p>Алуминиумската облога AluClip обезбедува практично неограничени можности за боје за елегантни алуминиумски дизајни и оптимални изолациски вредности кога се комбинира со модерниот PVC-U профилски систем Kömmerling 88.
Со својата елеганција, отпорност на временските услови и лесно одржување, материјалот алуминиум е убедливо решение во производството на прозорци. Пак, PVC-U обезбедува оптимална топлосна и звучна изолација, покрај функционалната надежност.
Kömmerling 88 AluClip ги обединува двете компоненти за вас: Алуминиум и PVC-U во совршена хармонија. И тоа со практички неограничен избор на бои за алуминиумските покривки благодарение на техники за завршување како анодизирање и прашина за завршување.
Одличните карактеристики на новата генерација профили не се само нивните својства за заштеда на енергија, туку и архитектурниот потенцијал кој нас води кон иднината:                                                                  Стабилната структура може да прифати тројно или ултра модерно функционално стакло со дебелина до 58 мм.
Kömmerling 88 овозможува големи прозорски површини за визуелно атрактивен дизајн на фасада со извонредни вредности за звучна изолација. Освен тоа, големите прозорски површини овозможуваат поголем пренос на соларна енергија - особено за време на студените сезони - и така ги намалува потребите за енергија и со тоа CO2 емисиите.
Овој систем исто така поставува пример од еколошка гледна точка - Комерлинг брендот е првиот кључен производител во светот кој произведува профили не само со рециклирани материјали во чист PVC-U, туку и исклучиво со PVC без олово со "зелени" стабилизатори базирани на калциум и цинк. Ова гарантира дека оние кои ги користат Комерлинг продуктите исто така работат на заштита на животната средина.
</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
          <div className='buttonBottom'>
<button className='mmInfoVazno'>Најважно</button>
<button className='mmInfoBrosuri' onClick={handleDownloadClick}>Брошури</button>
</div>
<ul className='mmDetails'>
  <li>Лесно за чистење, издржливо и отпорно.</li>
  <li>Изглед на алуминиум од надвор, неутрално бело однатре.</li>
  <li>Извонредна топлосна изолација во споредба со алуминиумски прозорци.</li>
  <li>Заштеда на ресурси со користење само на минимална количина алуминиум.</li>
  <li>Широк опсег на стакло до 58 мм за инсталација на тројно и специјално функционално стакло.</li>
  <li>Сите PVC-U профили се произведени со стабилизатори без олово, базирани на калциум и цинк.</li>
  <li>Офсетниот дизајн на прозорци со рамни линии и алуминиумски профили кои се спојуваат еден со друг.</li>
  <li>Скоро неограничен избор на боја на алуминиумските покривки благодарение на техники за завршување како анодизирање и прашина за завршување.</li>
</ul>
          </div>
        </div>
    </div>
  );
};

export default AluKlip88mm;