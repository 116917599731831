import './pages.css';
const ZebraVenecianeri = () => {
    return ( 
        <div className="container-fluid blueev-main">
        <div className="row bluerowspace">
            <div className="col-lg-6 col-md-12 col-sm-12 imageside">
            <img className="salamanderphoto pagesImages" src="./images/zebrazavesa123.webp" alt="" />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 infotext">
            <h2><span className='colored-text'>Зебра завеси (Zebra blinds)</span></h2>
            <p>Дизајн: Зебра завесите имаат уникатен дизајн со комбинација на прозрачни и не-прозрачни траки што се преминуваат една врз друга, слично на изгледот на зебрата (одатам и името).
Контрола на светлината: Овие завеси овозможуваат корисниците да регулираат количината на светлина во просторијата во зависност од положбата на траките. Кога траките се поставени една врз друга, светлината се блокира, додека кога се подредени, овозможуваат поголем пролаз на светлината.
Привлекателен изглед: Зебра завесите често се одбираат поради својот современ и елегантен изглед.</p>
            </div>
        </div>
        <div className="row bluerowspace">
            <div className="col-lg-6 col-md-12 col-sm-12 infotext">
                <h2><span className='colored-text'>Венецијанери (Venetian blinds)</span></h2>
                <p>Дизајн: Венецијанерите се составени од хоризонтални ламели (траки) кои можат да се поврзат заедно или растегнати за да се контролира количината на светлина и приватноста.
Контрола на светлината и приватноста: Венецијанерите овозможуваат прецизна контрола на светлината и приватноста. Ламелите можат да се отвораат, затвораат или ротираат за да се подеси аголот на светлината и видливоста.
Разновидни материјали: Венецијанерите се достапни во различни материјали, вклучувајќи дрво, алуминиум и ПВЦ. Секој материјал дава различен изглед и функционалност.
И двете облози имаат свои предности и се често бираат во зависност од стилот, функционалноста и личните предпочитувања на корисниците.
</p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 order-first order-md-1 pagesImages">
                <img className="salamanderphoto pagesImages" src="./images/roletni1.jpg" alt="" />
            </div>
        </div>
    </div>
     );
}
 
export default ZebraVenecianeri;