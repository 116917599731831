import './pages.css';
const AluKlip88mmVnatresno = () => {
  const handleDownloadClick = () => {
    // Replace 'path/to/your/pdf/document.pdf' with the actual path or URL of your PDF document
    const pdfSource = './images/Koemmerling-88-residential-door-AluClip-brochure.pdf';

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and href
    link.download = 'Koemmerling-88-residential-door-AluClip-brochure.pdf';
    link.href = pdfSource;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document body after the download starts
    document.body.removeChild(link);
  };
  return (
    <div className="container-fluid komBottomMargin">
        <div className='row kommerlingRowMarginLess'>
          <div className='col-12'>
          <h4>Премиум систем за врати за живеалишта - <span className='colored-text'>Kömmerling 88 AluClip </span> внатрешно отворање</h4><br/>
          <div id="carouselExampleControls" className="carousel slide imageFloat" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img className="d-block w-100" src="./images/88CentreSeal residentialDoor inwardOpening AluClipQuartz grey.jpg" alt="First slide"/>
    </div>
  </div>

</div>
      <p>Овој систем исто така е достапен со алуминиумски облог. Така се комбинираат предностите на алуминиумот и PVC-U во еден структурен елемент. Надворешно, елегантниот алуминиумски облог нуди речиси неограничени можности во дизајнот на бои, додека внатрешно, PVC-U профилите обезбедуваат ниско одржување, висока димензионална стабилност и сигурна функционалност.
Kömmerling 88 AluClip системите за врати за живеалишта се карактеризираат со многу технолошки специјалности кои обезбедуваат високо ниво на сигурност, одлична термална изолација, извонредна временска соодветност и добри статички својства. Со Kömmerling 88 AluClip премиум системот за врати за живеалишта, секогаш ќе донесете правилна одлука.
Системот за врати за живеалишта обезбедува сè што е потребно за висококвалитетни врати  со внатрешно отворање, вклучувајќи го и модерниот дебелински профил од 88 мм.
Вклучувањето на специјалниот хардвер ја подобрува безбедноста на уште повисоко ниво. Овозможува создавање единечни или повеќекрилни врати за живеалишта со горно светло или странични рамки. Куќната врата така станува прекрасен визиткар за вашиот дом, комбинирајќи естетска привлечност и напредни безбедносни карактеристики.
Овој систем исто така служи како пример од еколошка гледна точка - брендот Kömmerling е првиот клучен производител во светот кој произведува профили не само со регранулати во чист PVC-U прозорец, туку и исклучиво со безоловен PVC со "зелени" стабилизатори базирани на калциум и цинк. Ова гарантира дека оние кои ги користат Kömmerling производите исто така работат на заштита на животната средина.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
          <div className='buttonBottom'>
<button className='mmInfoVazno'>Најважно</button>
<button className='mmInfoBrosuri' onClick={handleDownloadClick}>Брошури</button>
</div>
<ul className='mmDetails'>
  <li>Максимални големини на крилата:<br/> 
Куќна/станбена врата, еднокрилни 1000 x 2200 мм<br/> Куќна/станбена врата, пловечко крило 900 x 2200 мм </li>
  <li>Изглед на алуминиум од надворешноста, неутрално бело од внатре.</li>
  <li>Извонредна термичка изолација во споредба со алуминиумските станбени врати.</li>
  <li>Класична бела боја, ламинирана со дрвени, рамни или метални бои.</li>
  <li>Сите PVC-U профили се произведени со стабилизатори без олово, базирани на калциум и цинк.</li>
  <li>Длабок раб за стакло за модерно тространо стакло, специјално функционално стакло или куќни врати со панели до 58 мм дебелина.</li>
  <li>Модерен дизајн со 6 камери со челична арматура со максимална големина и заварен аголен конектор за поголеми статички својства.</li>
  <li>Речиси неограничен избор на боја на алуминиумската обвивка поради техниките на завршна обработка како што се анодизирање и премачкување во прав.</li>
</ul>
          </div>
        </div>
    </div>
  );
};

export default AluKlip88mmVnatresno;