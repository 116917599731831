import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Footer from './components/Footer';
import Kommerling from './pages/Kommerling';
import SistemZaProzoriVrati from './pages/SistemZaProzoriVrati';
import Sistemi70mm from './pages/Sistemi70mm';
import PremiumProzorskiSistem from './pages/PremiumProzorskiSistem';
import About from './pages/About';
import Contact from './pages/Contact';
import Popup from './components/PopUp';
import TermsPrivacy from './pages/TermsPrivacy';
import Komarnici from './pages/Komarnici';
import AluminskiRoletni from './pages/AluminskiRoletni';
import PvcRoletni from './pages/PvcRoletni';
import Roletni from './pages/Roletni';
import PvcProzoriVrati from './pages/PvcProzoriVrati';
import PvcDodatoci from './pages/PvcDodatoci';
import PodprozorskaDaska from './pages/PodprozorskaDaska';
import ZebraVenecianeri from './pages/ZebraVenecianeri';
import AluminskiOkapnik from './pages/AlumiskiOkapnik';
import AluKlip88mm from './pages/AluKlip88mm';
import DvojnoZatvoranje88mm from './pages/DvojnoZatvoranje88mm';
import AluKlip88mmVnatresno from './pages/AluKlip88mmVnatresno';
import AluKlip88mmNadvoresno from './pages/AluKlip88mmNadvoresno';
import Sistemi88mm from './pages/Sistemi88mm';
import Sistemi76mm from './pages/Sistemi76mm';
import Centralno76mmStandard from './pages/Centralno76mmStandard';
import Centralno76mmAluKlip from './pages/Centralno76mmAluKlip';
import Dvojno76mmStandard from './pages/Dvojno76mmStandard';
import Dvojno76mmAluKlip from './pages/Dvojno76mmAluKlip';
import AluKlip76mmKonVnatre from './pages/AluKlip76mmKonVnatre';
import AluKlip76mmKonNadvor from './pages/AluKlip76mmKonNadvor';
import HibridniSistemi from './pages/HibridniSistemi';
import SistemiNaPragovi from './pages/SistemiNaPragovi';
import Blog from './pages/Blog';


function App() {
  const [isPopupOpen, setPopupOpen] = useState(true);

  const closePopup = () => {
    setPopupOpen(false);
  };
  return (
    <Router>
    <div className="App">
            <Navbar />
      <div className='mainappcontent'>
        <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/About" element={<About/>}/>
        <Route exact path="/Blog" element={<Blog/>}/>
        <Route exact path="/SistemZaProzoriVrati" element={<SistemZaProzoriVrati/>}/>
        <Route exact path="/Kommerling" element={<Kommerling/>}/>
        <Route exact path="/Sistemi70mm" element={<Sistemi70mm/>}/>
        <Route exact path="/PremiumProzorskiSistem" element={<PremiumProzorskiSistem/>}/>
        <Route exact path="/Contact" element={<Contact/>}/>
        <Route exact path="/TermsPrivacy" element={<TermsPrivacy/>}/>
        <Route exact path="/Komarnici" element={<Komarnici/>}/>
        <Route exact path="/PvcProzoriVrati" element={<PvcProzoriVrati/>}/>
        <Route exact path="/AluminskiRoletni" element={<AluminskiRoletni/>}/>
        <Route exact path="/PvcRoletni" element={<PvcRoletni/>}/>
        <Route exact path="/Roletni" element={<Roletni/>}/>
        <Route exact path="/PvcDodatoci" element={<PvcDodatoci/>}/>
        <Route exact path="/PodprozorskaDaska" element={<PodprozorskaDaska/>}/>
        <Route exact path="/ZebraVenecianeri" element={<ZebraVenecianeri/>}/>
        <Route exact path="/AluminskiOkapnik" element={<AluminskiOkapnik/>}/>
        <Route exact path="/AluKlip88mm" element={<AluKlip88mm/>}/>
        <Route exact path="/DvojnoZatvoranje88mm" element={<DvojnoZatvoranje88mm/>}/>
        <Route exact path="/AluKlip88mmVnatresno" element={<AluKlip88mmVnatresno/>}/>
        <Route exact path="/AluKlip88mmNadvoresno" element={<AluKlip88mmNadvoresno/>}/>
        <Route exact path="/Sistemi88mm" element={<Sistemi88mm/>}/>
        <Route exact path="/Sistemi76mm" element={<Sistemi76mm/>}/>
        <Route exact path="/Centralno76mmStandard" element={<Centralno76mmStandard/>}/>
        <Route exact path="/Centralno76mmAluKlip" element={<Centralno76mmAluKlip/>}/>
        <Route exact path="/Dvojno76mmStandard" element={<Dvojno76mmStandard/>}/>
        <Route exact path="/Dvojno76mmAluKlip" element={<Dvojno76mmAluKlip/>}/>
        <Route exact path="/AluKlip76mmKonVnatre" element={<AluKlip76mmKonVnatre/>}/>
        <Route exact path="/AluKlip76mmKonNadvor" element={<AluKlip76mmKonNadvor/>}/>
        <Route exact path="/HibridniSistemi" element={<HibridniSistemi/>}/>
        <Route exact path="/SistemiNaPragovi" element={<SistemiNaPragovi/>}/>
        </Routes>
        </div>
        <Footer />
        <Popup isOpen={isPopupOpen} onClose={closePopup} />
    </div>
    </Router>
  );
}

export default App;
