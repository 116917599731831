import './pages.css';
const PremiumProzorskiSistem = () => {
  const handleDownloadClick = () => {
    // Replace 'path/to/your/pdf/document.pdf' with the actual path or URL of your PDF document
    const pdfSource = './images/Koemmerling-88-centre-seal-brochure.pdf';

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and href
    link.download = 'Koemmerling-88-centre-seal-brochure.pdf';
    link.href = pdfSource;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document body after the download starts
    document.body.removeChild(link);
  };
  return (
    <div className="container-fluid komBottomMargin">
        <div className='row kommerlingRowMarginLess'>
          <div className='col-12'>
          <h4>Премиум прозорски систем - <span className='colored-text'>Kömmerling 88</span></h4><br/>
          <div id="carouselExampleControls" className="carousel slide imageFloat" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img className="d-block w-100" src="./images/Sistem88Standard1.jpg" alt="First slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/Sistem88Standard2.jpg" alt="Second slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/Sistem88Standard3.jpg" alt="Third slide"/>
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
      <p>Ова е премиум прозорец за новата ера: Kömmerling 88. Претставник на идната топлинска изолација, предводник во трендот на дизајн, пионер на квалитетот и надежноста. И иноватор во индивидуалноста благодарение на својата модуларност и иновативни технологии за надградба.
Kömmerling 88 е систем со средно затворено топлосно изолација со 7 камери и модерна длабочина на изградба од 88 мм. Стандардната верзија на системот има изузетна Uf вредност од 0.95 W/(m2K) и ги исполнува високите барања за заштеда на енергија за пасивни куќи. Но, посебноста е: За разлика од слични решенија, оваа вредност е постигната со челично арматурно ојачување за максимални димензии и на обоени профили.
Сепак, убедливите карактеристики на новата генерација профили не се само неговите својства за заштеда на енергија, туку и архитектурниот потенцијал ги насочува нашите погледи кон иднината: Стабилната структура може да прифати тројно или ултра модерно функционално стакло со дебелина до 58 мм. Kömmerling 88 овозможува големи прозорски површини за визуелно атрактивен дизајн на фасада со извонредни вредности за звучна изолација. Освен тоа, големите прозорски површини овозможуваат поголем пренос на соларна енергија - особено за време на студените сезони - и така ги намалува потребите за енергија и со тоа CO2 емисиите.
Освен класичната бела боја, системот има ламинати во различни бои - со дрвени, рамни и метални бои - кои се нанесуваат на надворешната страна (бела од внатре) или на двете страни.
Овој систем исто така поставува пример од еколошка гледна точка - Комерлинг брендот е првиот кључен производител во светот кој произведува профили не само со рециклирани материјали во чист PVC-U, туку и исклучиво со PVC без олово со "зелени" стабилизатори базирани на калциум и цинк. Ова гарантира дека оние кои ги користат Комерлинг продуктите исто така работат на заштита на животната средина.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
          <div className='buttonBottom'>
<button className='mmInfoVazno'>Најважно</button>
<button className='mmInfoBrosuri' onClick={handleDownloadClick}>Брошури</button>
</div>
<ul className='mmDetails'>
  <li>Лесно за чистење, издржливо и отпорно.</li>
  <li>Класична бела боја, ламинирана со дрвени, рамни или метални бои.</li>
  <li>Ултра модерна технологија за лесно ракување и надежно функционирање. </li>
  <li>Поверлив пренос на тежината на тежината на стакло до централното челично ојачување. </li>
  <li>Широк опсег на стакло до 58 мм за инсталација на тројно и специјално функционално стакло.</li>
  <li>Сите PVC-U профили се произведени со стабилизатори без олово, базирани на калциум и цинк.</li>
  <li>Подгодност за пасивни куќи, според дефиницијата на ift smjerilinija WA-15/2 - Uf вредност = 0.95 W/(m2K).</li>
  <li>Рамен дизајн со влезен профил со тенки видни линии што овозможуваат најголема количина светлина и постигнуваат високи добивки од соларна енергија.</li>
</ul>
          </div>
        </div>
    </div>
  );
};

export default PremiumProzorskiSistem;