import { Link } from "react-router-dom";
import "./pages.css";
const Roletni = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  return (
    <div className="container-fluid">
      <div className="row roletni">
        <div className="col-lg-6 col-md-12 col-sm-12 roletnileft">
          <div className="containerroletna">
            <img src="./images/roletni1.jpg" alt="" />
            <Link to="/AluminskiRoletni" onClick={handleClick}>
              <div class="overlay"></div>
            </Link>
            <Link to="/AluminskiRoletni" onClick={handleClick}><div class="centered-text">Алумински</div></Link>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 roletniright">
          <div className="containerroletna">
            <img src="./images/roletni2.jpg" alt="" />
            <Link to="/PvcRoletni" onClick={handleClick}>
              <div class="overlay"></div>
            </Link>
            <Link to="/PvcRoletni" onClick={handleClick}><div class="centered-text">ПВЦ</div></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roletni;
