import './pages.css';
const SistemiNaPragovi = () => {
  return (
    <div className="container-fluid komBottomMargin">
        <div className='row kommerlingRowMarginLess'>
          <div className='col-12'>
          <h4><span className='colored-text'>PremiPlan Plus</span> - Прeмиум систем за праг за максимална слобода на премин со
висок степен на достапност, за инсталација на ниво на тло на нула милиметри."</h4><br/>
      <img className='imageFloat' src='./images/Treshold1.jpg' alt=''/>
      <p>Овој систем за праг исто така се базира на стандардниот праг. Со користење на специјални дополнителни делови и рамен профил од конструкциски материјал, прагот се инсталира на ниво на тло од нула милиметри и на внатрешната и на надворешната страна.
Опциите за поврзување специјално дизајнирани за системот овозможуваат професионално поврзување со конструкцијата на објектот, користејќи течно затворање или затворачки мембрани, и професионална инсталација на дренажни системи.
Паралелното потегливо затворање на подот е главен дел од концептот за многустепено и континуирано затворање, кој постигнува максимални резултати преку специјално разработени структурни делови. Модуларниот дизајн на системот овозможува ефикаснo производство и инсталација.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
          <div className='buttonBottom'>
<button className='mmInfoVazno'>Најважно</button>
<button className='mmInfoBrosuri'>Брошури</button>
</div>
<ul className='mmDetails'>
  <li>PremiPlan Plus е систем за праг за максимална слобода на премин на ниво на тло на нула милиметри. </li>
  <li>Паралелното, повлекувачко затворање на подот е главниот компонент на концептот за многустепено и континуирано затворање. </li>
  <li>Нема лизгач на прагот. </li>
  <li>Погоден за Kömmerling 76 Unity, двојно и централно затворање, како и за Kömmerling 88 централно затворање на системот за прозори и врати.</li>
  <li>За врати со оклоп на нагон, влезни врати и врати за живеалишта.</li>
  <li>Заштита од киснели дождови, класа 9A. </li>
  <li>Безбедно поврзување со зглобови и плочи од нерѓосувачки челик.</li>
</ul>
          </div>
        </div>
    </div>
  );
};

export default SistemiNaPragovi;