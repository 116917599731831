import './pages.css';
const DvojnoZatvoranje88mm = () => {
  const handleDownloadClick = () => {
    // Replace 'path/to/your/pdf/document.pdf' with the actual path or URL of your PDF document
    const pdfSource = './images/Koemmerling-76-double-seal-brochure-2421130150-0423-web.pdf';

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and href
    link.download = 'Koemmerling-76-double-seal-brochure.pdf';
    link.href = pdfSource;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document body after the download starts
    document.body.removeChild(link);
  };
  return (
    <div className="container-fluid komBottomMargin">
        <div className='row kommerlingRowMarginLess'>
          <div className='col-12'>
          <h4><span className='colored-text'>Kömmerling 88 Двојно затворање </span></h4><br/>
      <img className='imageFloat' src='./images/88 AD standard in white.jpg' alt=''/>
      <p>Системот со двојно затворање Kömmerling 88 ги комбинира сите предности на модерните прозорци. Модерен и атрактивен дизајн во комбинација со длабочина на конструкција од 88 мм обезбедува совршен визуелен изглед на прозорците, и затворени и отворени.
Прозорецот Kömmerling 88 со двоен затвор ги исполнува сите применливи стандарди за заштита на животната средина, градежна физика и термичка и енергетска изолација. Овој прозорски систем е опремен со шест до седум камери, модерно тројно стакло или посебни прозорски стакла со дебелина до 58 мм. Едноставното ракување ја прави монтажата брза и лесна. Затоа, замената на старите куќни прозорци со нов Kömmerling 88 со двоен затвор не е проблем.
Овој систем исто така е погоден за куќи со ниска потрошувачка на енергија. Дури и во стандардната верзија, овој систем обезбедува коефициент на пренос на топлина од Uf 1,1 W/(m²K). Вредноста може да се подобри на 1,0 W/(m²K) со помош на додатоци. Искусете зголемена безбедност, квалитет на живот и топлосна и звучна изолација со овој систем. Нашите производи се достапни во сите можни форми, бои и големини. Дури и офсетни или дизајн крилја не се проблем. Ние го испорачуваме вашиот производ во бараната површина и боја. Различен изглед меѓу внатрешната и надворешната страна исто така е можно. На пример, може да ги имате прозорците на вашата куќа со модерен алуминиумски изглед на надворешната страна и класичен бел изглед на внатрешната страна. Вашиот специјалист за прозорци на Комерлинг со задоволство ќе ве консултира за вашите опции. Искористете ја нашата пребарувачка за специјализирани компании и добијте индивидуална понуда бесплатно.
И завршна забелешка на темата заштита на животната средина. Не е само заштедата на енергија она што прави да ја заштитиме животната средина со нашите производи. Ние сме првиот производител во светот кој користи рециклирани материјали и ПВЦ без олово. Ова го нагласува нашето тврдење дека сакаме да направиме нешто добро за животната средина.
</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
          <div className='buttonBottom'>
<button className='mmInfoVazno'>Најважно</button>
<button className='mmInfoBrosuri' onClick={handleDownloadClick}>Брошури</button>
</div>
<ul className='mmDetails'>
  <li>Достапен во варијанти AluClip, AluClip Pro и AddOn.</li>
  <li>Двоен прозорец со длабочина на конструкција од 88 мм.</li>
  <li>Прозорците може да се заштитат до класа на отпор RC 2.</li>
  <li>Користење на звучно-изолирачки стакла според потребите.</li>
  <li>Модерен, едноставен дизајн за два и три стакла со дебелина до 58 мм.</li>
  <li>Фитинзи може да се изберат според индивидуалните барања за безбедност.</li>
  <li>Балансирана конструкција на камерата со седум камери во крилото и шест во рамката.</li>
  <li>Коефициент на топлосен пренос со челик како стандард: Uf вредност = 1,1 W/(m2K).</li>
  <li>Коефициент на топлосен пренос со челик и дополнителна изолација: Uf вредност = 1,0 W/(m2K).</li>
  <li>PVC бел, еднобојни, метални бои или прозорци со дрвесен изглед - ламинирани на надворешната страна со PVC бела на внатрешната страна или ламинирани од двете страни.</li>
</ul>
          </div>
        </div>
    </div>
  );
};

export default DvojnoZatvoranje88mm;