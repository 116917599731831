import "./pages.css";
const HibridniSistemi = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  return (
    <div className="container-fluid blueev-main">
      <div className="row bluerowspace">
        <div className="col-lg-7 col-md-12 col-sm-12 infotextt">
          <h2>Системи од 76мм - 
            <span className="colored-text">Kömmerling Unity</span>
          </h2>
          <p>Kömmerling Unity е различен. Тоа е СОВРШЕНОТО решение, во кое PVC-U и алуминиумските материјали се развиени во хибриден систем преку соработка и користење на синергија. Kömmerling Unity убедува со својот кубичен дизајн. Со длабочина на изградба од 76 мм, е погоден за нови и реновирани објекти. Kömmerling Unity целосниот систем го вклучува системот за прозори и врати Kömmerling 76 Unity и системот за лизгачки врати Kömmerling PremiDoor 76 Unity.</p>
        </div>
      </div>
      <div className="row bluerowspace mm88Padding">
        <div className="col-lg-6 col-md-12 col-sm-12 align-self-start pageImagesText">
          <div to="/Sistemi88mm" onClick={handleClick}>
            <img
              className="kommerlingTypes"
              src="./images/HibridenStandard1.jpeg"
              alt=""
            />
          </div>
          <div to="/Sistemi88mm" onClick={handleClick}>
            <h5 className="whiteText">
            Систем за прозори и врати<br/>
              <span className="colored-text">Kömmerling 76 Unity</span>
            </h5>
          </div>
          <p className="infoPadTop">Чистиот дизајн се спојува со техничките барања. Длабочината на конструкцијата од 76 мм со вдлабнатина на рамката до 70 мм се најдобри предуслови за оптимална водоотпорност на објектите.</p>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 align-self-start pageImagesText">
          <div to="/Kom70mmSistemi" onClick={handleClick}>
            <img
              className="kommerlingTypes"
              src="./images/HibridenSistemSive2.jpeg"
              alt=""
            />
          </div>
          <div to="/Kom70mmSistemi" onClick={handleClick}>
          <h5 className="whiteText">
          Лизгачки системи<br/>
              <span className="colored-text">Kömmerling PremiDoor 76 Unity</span>
            </h5>
          </div>
          <p className="infoPadTop">Овој систем е совршен додаток на лизгачка врата за Kömmerling 76 Unity системот за прозори и врати. Елеганцијата и кубичниот дизајн се истакнуваат.</p>
        </div>
      </div>
    </div>
  );
};

export default HibridniSistemi;