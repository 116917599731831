import { Link } from "react-router-dom";
import "./pages.css";
const PvcProzoriVrati = () => {
  return (
    <div className="container-fluid blueev-main">
      <div className="row bluerowspace">
        <div className="col-lg-6 col-md-12 col-sm-12 pagesImages">
          <img className="salamanderphoto" src="./images/winwin-1.png" alt="" />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 infotext">
          <h2>
            Системи за прозори и станбени врати<br/><span className="colored-text">Kömmerling</span>
          </h2>
          <p>
          Kömmerling претставува широк спектар на системи за прозорци и ролетни. Нашиот широко разновиден опсег на системи, исто така, обезбедува основа за креативни решенија приспособени конкретно на сосема индивидуални потреби.
          </p>
        </div>
      </div>
      <div className="row bluerowspace">
        <div to="/Kom70mmSistemi" className="col-lg-4 col-md-4 col-sm-12 pagesImages">
        <Link to="/Kom70mmSistemi"><img className="kommerlingTypes" src="./images/Kommerling1.jpg" alt="" /></Link>
        <Link to="/Kom70mmSistemi"><p className="whiteText"><span className='colored-text'>Kömmerling</span><br/>88 мм системи</p></Link>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 pagesImages">
        <Link to="/Kom70mmSistemi"><img className="kommerlingTypes" src="./images/Kommerling2.jpg" alt="" /></Link>
        <Link to="/Kom70mmSistemi"><p className="whiteText"><span className='colored-text'>Kömmerling</span><br/>76 мм системи</p></Link>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 pagesImages">
        <Link to="/Kom70mmSistemi"><img className="kommerlingTypes" src="./images/Kommerling3.jpg" alt="" /></Link>
        <Link to="/Kom70mmSistemi"><p className="whiteText"><span className='colored-text'>Kömmerling</span><br/>70 мм системи</p></Link>
        </div>
      </div>
    </div>
  );
};

export default PvcProzoriVrati;