import './pages.css';
const Dvojno76mmAluKlip = () => {
  const handleDownloadClick = () => {
    // Replace 'path/to/your/pdf/document.pdf' with the actual path or URL of your PDF document
    const pdfSource = './images/Koemmerling-76-double-seal-brochure.pdf';

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and href
    link.download = 'Koemmerling-76-double-seal-brochure.pdf';
    link.href = pdfSource;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document body after the download starts
    document.body.removeChild(link);
  };
  return (
    <div className="container-fluid komBottomMargin">
        <div className='row kommerlingRowMarginLess'>
          <div className='col-12'>
          <h4><span className='colored-text'>Kömmerling 76 АлуКлип</span> со двојно затворање</h4><br/>
          <div id="carouselExampleControls" className="carousel slide imageFloat" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img className="d-block w-100" src="./images/76double seal AluClip black grey.jpg" alt="First slide"/>
    </div>
  </div>
</div>

      <p>Системот на PVC-U прозор со алуминиумско обложување што се поставува со клип-он метод обединува предности од двата материјали во еден структурен елемент. Надворешно, алуминиумското обложување нуди практично неограничени можности за дизајн со бои, додека на внатрешноста PVC-U профилите обезбедуваат ниско одржување, висока димензионална стабилност и пулсирачка функционалност.
Овој систем ги нуди сите предности што може да ви ги обезбедат модерните прозорци. Овој систем со двојно затворање, со пет камери и конструкциска длабочина од 76 мм, нуди фасцинантна комбинација на модерен дизајн и совршени пропорции на профилот.
Без разлика дали станува збор за функционалност, структурни својства, изолациски вредности, заштита на животната средина или зачувување на вредноста, тој ги исполнува барањата и на денешницата, и претежно на иднината. Kömmerling 76 системот со двојно затворање е толку иновативен дизајн што може да прифати модерни тројни стакла или посебни функционални стакла со ширина до 50 мм.
Системот ги исполнува барањата за енергетска ефикасност на ниски енергетски куќи - Uf вредност = 1.2 W/(m²K). Интелигентниот целосен дизајн ја подобрува топлинската и звучната изолација, а вие имате корист од поголем квалитет на живеење, економичност и безбедност. 
Овој систем исто така поставува пример од еколошка гледна точка - Комерлинг брендот е првиот клучен производител во светот кој произведува профили не само со рециклирани материјали во чист PVC-U, туку и исклучиво со PVC без олово со "зелени" стабилизатори базирани на калциум и цинк. Ова гарантира дека оние кои ги користат Комерлинг продуктите исто така работат на заштита на животната средина.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
          <div className='buttonBottom'>
<button className='mmInfoVazno'>Најважно</button>
<button className='mmInfoBrosuri' onClick={handleDownloadClick}>Брошури</button>
</div>
<ul className='mmDetails'>
  <li>Лесно за чистење, трајно и отпорно.</li>
  <li>Надворешен изглед на алуминиум, внатрешно неутрално бело.</li>
  <li>Изнесен дизајн на прозорци со рамни линии со споени алуминиумски профили.</li>
  <li>Зачувување на ресурси со користење само на минимална количина алуминиум.</li>
  <li>Сите PVC-U профили се произведени со стабилизатори без олово, базирани на калциум и цинк.</li>
  <li>Голем опсег на стакло до 50 мм за монтажа на тројни и посебни функционални стакла.</li>
  <li>Екстраординарна термална изолација во споредба со алуминиумски прозорци - Uf вредност = 1,2 W/(m²K).</li>
  <li>Скоро неограничен избор на бои на алуминиумските облози поради техники на завршување како анодизација и порцеланско покривање.</li>
</ul>
          </div>
        </div>
    </div>
  );
};

export default Dvojno76mmAluKlip;