import { Link } from 'react-router-dom';
import './footer.css';

const Footer = () => {

    const handleClick = () => {
      window.scrollTo({ top: 0, behavior: 'auto' });
    };
    return ( 
        <div className="footerWrap">
        <div className="footer">
            <div className="leftoffooter">
               <Link className='textwhite' to='https://maps.app.goo.gl/5xmCza6Svk833GVHA' target="_blank" onClick={handleClick}> <div className="footerItems iconcolor"><i className="footerIcon fa-solid fa-location-dot"></i>Ул.1520 бр.2 Скопје</div></Link> 
               <Link className='textwhite' to='https://www.whatsapp.com/' target="_blank"><div className="footerItems iconcolor" onClick={handleClick}><i className="footerIcon fa-brands fa-whatsapp"></i>+389 71 700 760</div></Link>
               <Link className='textwhite' to='https://www.whatsapp.com/' target="_blank"><div className="footerItems iconcolor" onClick={handleClick}><i className="footerIcon fas fa-phone fa"></i>+389 78 335 737</div></Link>
               <Link className='textwhite' to='https://www.facebook.com/profile.php?id=61553231966045' target="_blank" onClick={handleClick}><div className="footerItems iconcolor"><i className="footerIcon fa-brands fa-facebook"></i>ОКНА_Пвц</div></Link>
               <Link className='textwhite' to='https://www.outlook.com/ target="_blank"'><div className="footerItems iconcolor" onClick={handleClick}><i className="footerIcon fa-regular fa-envelope"></i>admin@okna.com.mk</div></Link>
            </div>
            <div className="rightoffooter">
           <Link className='textwhite' to='TermsPrivacy' onClick={handleClick}> <div className="footerItems iconcolor">Општи услови за користење</div></Link>
           <Link className='textwhite' to='TermsPrivacy' onClick={handleClick}><div className="footerItems iconcolor">Политика за приватност</div></Link>
           <Link className='textwhite' to='TermsPrivacy' onClick={handleClick}><div className="footerItems iconcolor">Политика за колачиња</div></Link>
            </div>
        </div>
        <div className="allRightsRes">
            <p>Copyright &copy; 2023. ОКНА пвц прозори Скопје. Сите права се задржани.</p>
        </div>
        </div>
     );
}
 
export default Footer;