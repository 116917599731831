import './pages.css';
const AluKlip76mmKonVnatre = () => {
  const handleDownloadClick = () => {
    // Replace 'path/to/your/pdf/document.pdf' with the actual path or URL of your PDF document
    const pdfSource = './images/Koemmerling-76-door-system-brochure.pdf';

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and href
    link.download = 'Koemmerling-76-door-system-brochure.pdf';
    link.href = pdfSource;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document body after the download starts
    document.body.removeChild(link);
  };
  return (
    <div className="container-fluid komBottomMargin">
        <div className='row kommerlingRowMarginLess'>
          <div className='col-12'>
          <h4>Врати за живеалишта -<span className='colored-text'>Kömmerling 76 AluClip </span>  внатрешно отворање</h4><br/>
      <img className='imageFloat' src='./images/76residential door AluClip inward opening brushed stainless steel.jpg' alt=''/>
      <p>Вратите за живеалишта со о алуминиумско обложување ги комбинира предностите од двата материјали во еден структурен елемент. Надворешно, елегантниот алуминиумски облог нуди речиси неограничени можности во дизајнот на бои, додека внатрешно, PVC-U профилите обезбедуваат ниско одржување, висока димензионална стабилност и сигурна функционалност.
Kömmerling 76 AluClip системите за врати за живеалишта обезбедува сè што е потребно за висококвалитетни врати  со внатрешно отворање. Високо-изолативниот пет камерен систем обезбедува оптимална заштита од топлина и ладно. Резиденцијалните врати Kömmerling 76 исто така покажуваат висока основна стабилност. Големите челични засилувања создаваат цврст, затворен рам.
Аглите на појасот се засилени со заварени аголни конектори кои формираат непозитивна конекција со челичното засилување. Овие аголни конектори ги апсорбираат силите на флексибилност и торзија, на пример, при обиди за напад со ломачка, и ги пренесуваат низ целиот околен челичен рам. Специјалната апаратура го зголемува нивото на безбедност на уште поголемо ниво.
Специјалната технологија за прикачување осигурува безнапорна врска помеѓу PVC-U и алуминиумските материјали. Постојат единечни или  повеќекрилни врати за живеалишта со внатрешно отворање со горно светло или странични рамки. Вратите за живеалишта можат да се монтираат според вашите желби со грузски пречки, конвенционалнo функционални или украсни стакла, или префабрикувани панели.
Овој систем исто така служи како пример од еколошка гледна точка - брендот Kömmerling е првиот клучен производител во светот кој произведува профили не само со регранулати во чист PVC-U прозорец, туку и исклучиво со безоловен PVC со "зелени" стабилизатори базирани на калциум и цинк. Ова гарантира дека оние кои ги користат Kömmerling производите исто така работат на заштита на животната средина.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
          <div className='buttonBottom'>
<button className='mmInfoVazno'>Најважно</button>
<button className='mmInfoBrosuri' onClick={handleDownloadClick}>Брошури</button>
</div>
<ul className='mmDetails'>
  <li>Максимални големини на крилата: <br/>
  Куќна/станбена врата, еднокрилни 1100 x 2200 мм<br/>Куќна/станбена врата, пловечко крило 900 x 2200 мм </li>
  <li>За специјални функционални стакла или панели за врати до 50 мм.</li>
  <li>Високо ниво на заштита од присилно влегување - до класа на отпор RC 2.</li>
  <li>Прагов конектор за прецизно монтирање и запечатување помеѓу рамка и праг.</li>
  <li>Сите PVC-U профили се произведени со стабилизатори без олово, базирани на калциум и цинк.</li>
  <li>Висока затегнатост со двојно затворање до праг без бариери со термички прекин.</li>
  <li>Дизајн со 5 камери со челична арматура со максимална големина и заварен аголен конектор за поголеми статички својства.</li>
  <li>Вратниот систем за станови со висок изолационен коефициент со 76 мм длабочина на конструкција, со просек Uf = 1.2 W/(m²K).</li>
</ul>
          </div>
        </div>
    </div>
  );
};

export default AluKlip76mmKonVnatre;