import React, { useState } from 'react';
import './BlogPage.css';

const Blog = [
  {
    id: 1,
    title: "First Blog Post",
    date: "2023-06-01",
    content: "This is the content of the first blog post.",
    image: "https://via.placeholder.com/150"
  },
  {
    id: 2,
    title: "Second Blog Post",
    date: "2023-06-15",
    content: "This is the content of the second blog post.",
    image: "https://via.placeholder.com/150"
  },
  {
    id: 3,
    title: "Most Recent Blog Post",
    date: "2023-06-23",
    content: "This is the content of the most recent blog post.",
    image: "https://via.placeholder.com/150"
  },
  {
    id: 4,
    title: "Benefits of PVC Windows",
    date: "2023-05-10",
    content: "PVC windows offer great insulation and durability.",
    image: "https://via.placeholder.com/150"
  },
  {
    id: 5,
    title: "Installing PVC Doors",
    date: "2023-04-22",
    content: "A guide on how to install PVC doors efficiently.",
    image: "https://via.placeholder.com/150"
  },
  {
    id: 6,
    title: "Maintaining Your PVC Windows",
    date: "2023-03-15",
    content: "Tips and tricks for maintaining PVC windows.",
    image: "https://via.placeholder.com/150"
  },
  {
    id: 7,
    title: "Why Choose PVC Doors",
    date: "2023-02-05",
    content: "PVC doors are affordable and offer great security.",
    image: "https://via.placeholder.com/150"
  },
  {
    id: 8,
    title: "PVC Windows vs. Traditional Windows",
    date: "2023-01-20",
    content: "Comparing PVC windows to traditional windows.",
    image: "https://via.placeholder.com/150"
  },
  {
    id: 9,
    title: "Eco-Friendly PVC Windows",
    date: "2022-12-25",
    content: "How PVC windows contribute to eco-friendly living.",
    image: "https://via.placeholder.com/150"
  },
  {
    id: 10,
    title: "Cost-Effective PVC Solutions",
    date: "2022-11-15",
    content: "PVC solutions that are cost-effective.",
    image: "https://via.placeholder.com/150"
  },
  {
    id: 11,
    title: "Advanced PVC Technologies",
    date: "2022-10-30",
    content: "Exploring advanced technologies in PVC.",
    image: "https://via.placeholder.com/150"
  },
  {
    id: 12,
    title: "PVC in Modern Construction",
    date: "2022-09-22",
    content: "The role of PVC in modern construction.",
    image: "https://via.placeholder.com/150"
  }
];

const BlogPage = () => {
  const [visibleBlogs, setVisibleBlogs] = useState(4); // Show the most recent blog plus 3 more initially

  // Sort the blog posts by date in descending order
  const sortedPosts = Blog.sort((a, b) => new Date(b.date) - new Date(a.date));

  const showMoreBlogs = () => {
    setVisibleBlogs((prevVisibleBlogs) => Math.min(prevVisibleBlogs + 3, sortedPosts.length));
  };

  return (
    <div className="blog-container">
      <div className="blog-post first-post">
        <div className="blog-content-container">
          <div>
            <h2 className="blog-title">{sortedPosts[0].title}</h2>
            <p className="blog-date"><em>{new Date(sortedPosts[0].date).toDateString()}</em></p>
            <p className="blog-content">{sortedPosts[0].content}</p>
            <button className="read-more-button">Read More</button>
          </div>
          <img src={sortedPosts[0].image} alt={sortedPosts[0].title} className="blog-image"/>
        </div>
      </div>
      <div className="blog-posts-grid">
        {sortedPosts.slice(1, visibleBlogs).map((post) => (
          <div key={post.id} className="blog-post other-post">
            <div className="blog-content-container">
              <div>
                <h2 className="blog-title">{post.title}</h2>
                <p className="blog-date"><em>{new Date(post.date).toDateString()}</em></p>
                <p className="blog-content">{post.content}</p>
                <button className="read-more-button">Read More</button>
              </div>
              <img src={post.image} alt={post.title} className="blog-image"/>
            </div>
          </div>
        ))}
      </div>
      {visibleBlogs < sortedPosts.length && (
        <button className="show-more-button" onClick={showMoreBlogs}>Show More</button>
      )}
    </div>
  );
};

export default BlogPage;
