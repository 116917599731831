import { Link } from "react-router-dom";
import "./pages.css";
const Home = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  return (
    <div className="home-content">
      <div className="image-container">
      <div id="carouselExampleControls" className="carousel slide " data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img className="d-block w-100" src="./images/Home11.1.jpg" alt="First slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/Home2.1.jpg" alt="Second slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="./images/Home1.1.jpg" alt="Third slide"/>
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
  <p className="textOnLeft">ОКНА ви го претставува Kömmerling водечкиот производител на ПВЦ профили за изработка на високо квалитетни ПВЦ прозорци и врати</p>
</div>
<div className="container-fluid">
      <div className="row">
        <div className="col-lg-4 col-md-12 col-sm-12 homeThreeButtons komColor">
          <Link className="threeButtonsLink" to="/">
            <div className="homeThreeButtonInside">
                ПОБАРАЈ ПОНУДА
      </div>
    </Link>
      </div>
        <div className="col-lg-4 col-md-12 col-sm-12 borderleftright homeThreeButtons">
          <Link className="threeButtonsLink" to="/">
            <div className="homeThreeButtonInside">
            БРОШУРИ
        </div>
      </Link>
      </div>
        <div className="col-lg-4 col-md-12 col-sm-12 homeThreeButtons komColor">
          <Link className="threeButtonsLink" to="/">
            <div className="homeThreeButtonInside">
                ЗАКАЖИ КОНСУЛТАЦИЈА
        </div>
      </Link>
      </div>
      </div>
    </div>
      </div>
        <div className="container-fluid homeUnder">
        <h1 className="home2text">Зошто Окна и Kömmerling?</h1>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 leftColumnHome">
              <img className="homeLeftImg" src="./images/HomeLeftSide.jpg" alt="house"/>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 rightColumnHome">
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12 offset-lg-1 mb-5 boxButton"><i class="fa-solid fa-circle-check"></i>СУПЕРИОРНА ИЗДРЖЛИВОСТ</div>
                <div className="col-lg-5 col-md-12 col-sm-12 offset-lg-1 mb-5 boxButton"><i class="fa-solid fa-temperature-arrow-down"></i>ТЕРМАЛНА ИЗОЛАЦИЈА</div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12 offset-lg-1 mb-5 boxButton"><i class="fa-solid fa-palette"></i>МОДЕРЕН ДИЗАЈН</div>
                <div className="col-lg-5 col-md-12 col-sm-12 offset-lg-1 mb-5 boxButton"><i class="fa-solid fa-sun"></i>ЗГОЛЕМЕНА СВЕТЛИНА</div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12 offset-lg-1 mb-5 boxButton"><i class="fa-solid fa-coins"></i>ЕНЕРГЕТСКА ЕФИКАСНОСТ</div>
                <div className="col-lg-5 col-md-12 col-sm-12 offset-lg-1 mb-5 boxButton"><i class="fa-solid fa-volume-xmark"></i>ЗВУЧНА ИЗОЛАЦИЈА</div>
                <Link to="Kommerling" className="col-lg-5 col-md-12 col-sm-12 offset-lg-4 boxBottomButton">ДОПОЛНИТЕЛНИ ТЕХНИЧКИ КАРАКТЕРИСТИКИ</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid homeLast">
        <div className="row bluerowspace">
        <div className="col-lg-4 col-md-4 col-sm-12 pagesImages">
        <Link to="/Sistemi70mm" onClick={handleClick}><img className="kommerlingTypes" src="./images/Kommerling1.jpg" alt="" /></Link>
        <Link to="/Sistemi70mm" onClick={handleClick} className="center88Text"><p className="whiteText"><span className='colored-text'>Kömmerling</span><br/>70 мм системи</p></Link>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 pagesImages">
        <Link to="/Sistemi76mm" onClick={handleClick}><img className="kommerlingTypes" src="./images/Kommerling2.jpg" alt="" /></Link>
        <Link to="/Sistemi76mm" onClick={handleClick} className="center88Text"><p className="whiteText"><span className='colored-text'>Kömmerling</span><br/>76 мм системи</p></Link>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 pagesImages">
        <Link to="/Sistemi88mm" onClick={handleClick}><img className="kommerlingTypes" src="./images/Kommerling3.jpg" alt="" /></Link>
        <Link to="/Sistemi88mm" onClick={handleClick} className="center88Text"><p className="whiteText"><span className='colored-text'>Kömmerling</span><br/>88 мм системи</p></Link>
        </div>
      </div>
        </div>
        <div className="project-implementation-container">
      <h2 className="titlee">Реализација на проект</h2>
      <div className="contentt">
        <div className="stepss">
          <div className="stepp">
          <i className="fa-solid fa-envelope iconn"></i>
            <div className="text-contentt">
              <h3>Испрати барање</h3>
              <p>
                Нашиот тим ќе го разгледа вашето барање за да ви испрати понуда која што ќе одговара најмногу на вашите потреби.
              </p>
            </div>
          </div>
          <div className="stepp">
          <i className="fa-solid fa-spinner iconn"></i>
            <div className="text-contentt">
              <h3>Обработка на понуда</h3>
              <p>
                Ја изработуваме понудата по димензии, боја и ширина на профил одбрани по ваш избор. Добивате и конкретна цена за секоја позиција и дополнителни информации за профилот.
              </p>
            </div>
          </div>
          <div className="stepp">
          <i className="fa-regular fa-handshake iconn"></i>
            <div className="text-contentt">
              <h3>Понуда и дополнителни информации</h3>
              <p>
                Прифаќање на понуда и договор околу следните чекори. Нашиот тим од на терен за да земе официјални димензии за проектот.
              </p>
            </div>
          </div>
          <div className="stepp">
          <i className="fa-solid fa-house-chimney-window iconn"></i>
            <div className="text-contentt">
              <h3>Изработка и монтажа</h3>
              <p>
                Тим од професионалци по ПВЦ индустријата ги изработуваат и монтираат производите согласно Европски стандарди за квалитет.
              </p>
            </div>
          </div>
        </div>
        <div className="image-containerr">
          <img src="./images/Green Blue Modern Photo Nature Photo Collage.png" alt="Project Implementation" className="main-imagee"/>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Home;