import './pages.css';
const TermsPrivacy = () => {
    return ( 
        <div className="aboutInfo">

<div className="termsandconditions">
    <h4>1. Услови и правила:</h4><br/>

<h5>Вовед:</h5>
Добредојдовте на Okna! Користењем нашата веб-страница, се согласувате да се придржувате кон следниве услови и правила.<br/><br/>
<h6>1. Собирање на податоци:</h6>
Собираме и чуваме имиња и е-пошти доброволно обезбедени од корисниците преку контакт формата и искачечканиот модален прозорец за целите на информирање за промоции.<br/><br/>
<h6>2. Промотивни е-пошти:</h6>
Со испраќање на вашите информации, се согласувате да добивате промотивни е-пошти од Okna. Можете да се отпишете во било кое време следејќи ги упатствата во е-поштите.<br/><br/>
<h6>3. Обврски на корисниците:</h6>
Корисниците не се задолжени да креираат профили. Сепак, се одговорни за обезбедување на точни и ажурни информации.<br/><br/>
<h6>4. Измена на условите:</h6>
Задржуваме право да ги изменуваме овие услови во било кое време. Промените ќе бидат ефикасни веднаш откако ќе бидат објавени на веб-страницата.<br/><br/>
<h6>5. Контакт информации:</h6>

За било какви прашања во врска со овие услови, можете да не контактирате на okna@okna.com.</div><br/><br/>
        <div className="privacypolicy">
            <h4>2. Политика за приватност:</h4><br/>

<h6>1. Информации кои ги собираме:</h6>
Собираме и чуваме имиња и е-пошти доброволно обезбедени од корисниците преку контакт формата и искачечканиот модален прозорец.<br/><br/>
<h6>2. Употреба на информациите:</h6>
Собраните информации се користат исклучиво за целите на информирање на корисниците за промоции и обнови од Okna.<br/><br/>
<h6>3. Заштита на податоците:</h6>
Предпремаме разумни мерки за заштита на безбедноста на податоците на корисниците. Сепак, ниеден метод на пренос преку интернет или електронско зачувување не е целосно безбеден.<br/><br/>
<h6>4. Услуги од трети лица:</h6>
Не споделуваме информации за корисниците со услуги од трети лица.<br/><br/>
<h6>5. Колачиња:</h6>
Не користиме колачиња на нашата веб-страница.<br/><br/>
<h6>6. Права на корисниците:</h6>
Корисниците имаат право да бараат пристап, исправка или бришење на нивните информации. За да ги испратите овие права, контактирајте не на okna@okna.com.<br/><br/>
<h6>7. Промени во политиката:</h6>
Сите промени на политиката за приватност ќе бидат одразени на оваа страница.<br/><br/>
<h6>8. Контакт информации:</h6>
За прашања во врска со приватноста или барања, ве молиме контактирајте не на okna@okna.com.<br/>
        </div>
        </div>
     );
}
 
export default TermsPrivacy;