import { useState, useRef } from "react";
import "./pages.css";

const Contact = () => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.add("dragover");
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.remove("dragover");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.remove("dragover");

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setFile(e.dataTransfer.files[0]);
      fileInputRef.current.files = e.dataTransfer.files; // Update the file input
      e.dataTransfer.clearData();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    // Append the file to the form data
    if (file) {
      formData.append("file", file);
    }

    try {
      const response = await fetch("/", {
        method: "POST",
        body: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.ok) {
        form.reset();
        setFile(null);
        alert("Form submitted successfully!");
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      console.error("Form submission error:", error);
      alert("Form submission failed.");
    }
  };



  return (
    <div className="contactWholeWrapper">
      <div className="container-fluid iconTopContact">
        <div className="row">
          <div className="col-lg-2 col-md-5 col-sm-5 offset-1 mt-4 borderToIcons">
            <i class="fa-solid fa-phone"></i>
            <h6>Телефон</h6>
            <p>+38971 700-760</p>
            <p>+38978 335-737</p>
          </div>
          <div className="col-lg-2 col-md-5 col-sm-5 offset-1 mt-4 borderToIcons">
            <i class="fa-solid fa-location-dot"></i>
            <h6>Адреса</h6>
            <p>
              Ул. 1520 бр. 2<br />
              Скопје, Северна Македонија
            </p>
          </div>
          <div className="col-lg-2 col-md-5 col-sm-5 offset-1 mt-4 borderToIcons">
            <i class="fa-regular fa-paper-plane"></i>
            <h6>Е-маил</h6>
            <p>admin@okna.com.mk</p>
          </div>
          <div className="col-lg-2 col-md-5 col-sm-5 offset-1 mt-4 borderToIcons">
            <i class="fa-regular fa-paper-plane"></i>
            <h6>Социјални медиуми</h6>
            <p>
              Facebook
              <br />
              Instagram
              <br />
              LinkedIn
              <br />
              Whatsapp
            </p>
          </div>
        </div>
      </div>
      <section className="mb-4 contactpadding">
        <div className="row contactPaddingAll">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-md-0 mb-5 formDivLeft paddingForm">
            <form
              id="contact-form"
              name="contact-form"
              method="post"
              onSubmit={handleSubmit}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              encType="multipart/form-data"
            >
              <h2 className="h1-responsive font-weight-bold text-center my-4">
                Пишете ни
              </h2>
              <input type="hidden" name="form-name" value="contact-form" />
              <input type="hidden" name="bot-field" />
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="md-form mb-0">
                    <input
                      required
                      type="text"
                      id="name"
                      placeholder="Име"
                      name="name"
                      className="form-control formMargin"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="md-form mb-0">
                    <input
                      required
                      type="email"
                      id="email"
                      placeholder="Меил адреса"
                      name="email"
                      className="form-control formMargin"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="md-form mb-0">
                    <input
                      type="text"
                      id="subject"
                      placeholder="Телефон"
                      name="subject"
                      className="form-control formMargin"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="md-form">
                    <textarea
                      type="text"
                      id="message"
                      placeholder="Порака"
                      name="message"
                      rows="2"
                      className="form-control md-textarea formMargin fixedsizeoftext"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="custom-file-upload"
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClick={() => fileInputRef.current.click()}
                  >
                    <i className="fas fa-cloud-upload-alt"></i>
                    {file
                      ? file.name
                      : "Кликни или повлечи го фајлот за прикачување"}
                  </div>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    accept=".jpg,.png,.pdf,.doc,.docx"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary submitButtonSize"
              >
                Send
              </button>
            </form>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12 offset-lg-1 offset-md-0 offset-sm-0 text-center formDivLeft">
            <img
              className="homeLeftImg contactImg"
              src="./images/HomeLeftSide.jpg" alt="house"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
