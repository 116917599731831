import './pages.css';
const EvoDrive = () => {
    return ( 
        <div className="container-fluid blueev-main">
        <div className="row bluerowspace">
            <div className="col-lg-6 col-md-12 col-sm-12">
            <img className="salamanderphoto pagesImages aluRoletna" src="./images/aluminskaroletna111.jpeg" alt="" />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 infotext">
            <h2><span className='colored-text'>ПВЦ надградни ролетни</span></h2>
            <p>* Изработени се од материјали на реномирани светски производители (Експалум - Шпанија, Ролка - Грција).<br/>
* Поставени се над прозорецот<br/>
* Кутијата со ролери има едноставен дизајн со малку заоблени рабови.<br/>
* Бои: бела или во RAL бои по барање на купувачот.брата и водилките можат да бидат направени од алуминиум или ПВЦ.</p>
            </div>
        </div>
        <div className="row bluerowspace">
            <div className="col-lg-6 col-md-12 col-sm-12 infotext">
                <h2><span className='colored-text'>ПВЦ надворешни ролетни</span></h2>
                <p>*Изработени се од материјали од реномирани светски производители (Expalum - Шпанија, Rolka - Грција).<br/>
*Поставени се на надворешната страна на прозорецот.<br/>
*Ребрата и водилките можат да бидат направени од алуминиум или ПВЦ.<br/>
*Слепата кутија може да биде пентагонална или со малку заоблени рабови.<br/>
*Погонот може да биде ремен, јаже или електричен мотор.<br/>
*Бои: бела или во RAL бои по барање на купувачот.</p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 order-first order-md-1 pagesImages">
            <img className="salamanderphoto pagesImages" src="./images/aluminskaroletna123.webp" alt="" />
            </div>
        </div>
    </div>
     );
}
 
export default EvoDrive;