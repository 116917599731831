import { Link } from "react-router-dom";
import "./pages.css";
const Sistemi76mm = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  return (
    <div className="container-fluid blueev-main">
      <div className="row bluerowspace">
        <div className="col-lg-7 col-md-12 col-sm-12 infotextt">
          <h2>
            76мм Системи
            <br />
            <span className="colored-text">Kömmerling</span>
          </h2>
          <p>76-милиметрските системи со двојно и централно затворање се идеални за реновации и нови објекти. Малата длабочина на изградбата и тенките профилни видни линии обезбедуваат најдобри предности за реновации.</p>
        </div>
      </div>
      <div className="row bluerowspace mm88Padding">
        <div className="col-lg-4 col-md-12 col-sm-12 align-self-start pageImagesText">
          <div>
            <img
              className="kommerlingTypes"
              src="./images/Kommerling1.jpg"
              alt=""
            />
          </div>
          <div>
            <h5 className="whiteText">
              <span className="colored-text">Kömmerling 76</span>
              <br />
              Централно Затворање
            </h5>
          </div>
          <p className="infoPadTop">Освен надворешниот и внатрешниот временски затвор, системите со централно затворање се опремени и со трето, централно ниво на затворање. Ова придонесува за подобри термички и звучни изолациски вредности.</p>
          <div className="row textStartRow">
            <div className="col-12 textStartCol"><Link to="/Centralno76mmStandard" className="mmSubLinks" onClick={handleClick}><i class="fa-solid fa-chevron-right"></i> Стандарден</Link></div>
            <div className="col-12 textStartCol" ><Link to="/Centralno76mmAluKlip" className="mmSubLinks" onClick={handleClick}><i class="fa-solid fa-chevron-right"></i> АлуКлип</Link></div>
          </div> 
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 align-self-start pageImagesText">
          <div>
            <img
              className="kommerlingTypes"
              src="./images/Kommerling2.jpg"
              alt=""
            />
          </div>
          <div>
            <h5 className="whiteText">
              <span className="colored-text">Kömmerling 76</span>
              <br />
              Двојно Затворање
            </h5>
          </div>
          <p className="infoPadTop">Системите со двојно затворање се опремени со две нивоа на затворање. Надворешниот временски затвор е во ногата на рамката, додека внатрешниот е внатре во ногата на крилото.</p>
          <div className="row textStartRow">
            <div className="col-12 textStartCol"><Link to="/Dvojno76mmStandard" className="mmSubLinks" onClick={handleClick}><i class="fa-solid fa-chevron-right"></i> Стандарден</Link></div>
            <div className="col-12 textStartCol" ><Link to="/Dvojno76mmAluKlip" className="mmSubLinks" onClick={handleClick}><i class="fa-solid fa-chevron-right"></i> АлуКлип</Link></div>
          </div> 
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 align-self-start pageImagesText">
          <div>
            <img
              className="kommerlingTypes"
              src="./images/Kommerling3.jpg"
              alt=""
            />
          </div>
          <div>
            <h5 className="whiteText">
              <span className="colored-text">Kömmerling 76</span>
              <br />
              Влезна Врата за живеалишта
            </h5>
          </div>
          <p className="infoPadTop">Kömmerling системите за влезни врати за живеалишта се достапни во верзии со насочување кон внатре и кон надвор. Освен структурирано стакло, елементите на дизајн можат да бидат и во форма на предизработени панели и модерни вметнати панели за влезни врати за живеалишта.</p>
          <div className="row textStartRow">
            <div className="col-12 textStartCol"><Link to="/AluKlip76mmKonVnatre" className="mmSubLinks" onClick={handleClick}><i class="fa-solid fa-chevron-right"></i> АлуКлип отворање кон внатре</Link></div>
            <div className="col-12 textStartCol"><Link to="/AluKlip76mmKonNadvor" className="mmSubLinks" onClick={handleClick}><i class="fa-solid fa-chevron-right"></i> АлуКлип отворање кон надвор</Link></div>
          </div> 
        </div>
      </div>
    </div>
  );
};

export default Sistemi76mm;
